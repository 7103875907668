import cx from 'classnames';
import {
  graphql,
  PageProps,
} from 'gatsby';
import Img from 'gatsby-image';
import React, { ReactElement, useEffect } from 'react';
import Category from '../components/Category/Category';
import CatList from '../components/CategoryList/CatList';
import PageFooter from '../components/pageFooter/pageFooter';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';
import {
  ScreenType,
  useScreen,
} from '../hooks/useScreen';
import { useMdData } from '../static-queries/useBlogData';
import blogTemplateStyles from './blog.module.scss';
// import { CommentCount, Disqus } from 'gatsby-plugin-disqus';

export default function ProjectTemplate({
  data,
}: PageProps<{
  site: any,
  markdownRemark: any,
}>): ReactElement {
  const md = data.markdownRemark;
  const blogData = useMdData();
  const allBlogData =
    blogData.posts.edges;

  const {screen} = useScreen();
  const {setIsSideBarShown} = usePageContext()

  useEffect(() => {
    setIsSideBarShown(true)
    return () => {
    };
  }, []);

  
  function getNextSlug(slug: string) {
    const allSlugs = allBlogData.map(
      (blog: any) =>
        blog.node.fields.slug
    );
    const nextSlug =
      allSlugs[
        allSlugs.indexOf(slug) + 1
      ];
    if (
      nextSlug !== undefined &&
      nextSlug !== ''
    ) {
      return nextSlug;
    }
    return allSlugs[0];
  }

  const nextSlug = getNextSlug(
    md.fields.slug
  );

  const renderContent = (content: string) => {
    return content.replace(/<!-- cv-start -->([\s\S]*?)<!-- cv-end -->/gm, '');; // Return the full content for CV page or default case
  };

  // let disqusConfig = {
  //   url: `${config.siteUrl+location.pathname}`,
  //   identifier: md.frontmatter.title,
  //   title: md.frontmatter.title,
  // }
  return (
    <>
      <SEO
        title={md.frontmatter.title}
        description={md.excerpt}
        rrssImg={
          md.frontmatter.hero_image
            ? md.frontmatter.hero_image
                .publicURL ||
              md.frontmatter.hero_image
            : ''
        }
      />
      <div
        className={
          blogTemplateStyles.backgroundProject
        }></div>

      <article
        className={cx(
          'w-full md:px-6 pb-6 min-h-screen flex items-center flex-col',
          blogTemplateStyles.mainContainer
        )}>
        <div
          className={
            'hidden md:block w-full'
          }>
          <CatList />
        </div>
        <section className="md:w-basic w-full md:mt-0 mt-20">
          <div
            className={cx(
              blogTemplateStyles.blog,
              'pb-20 md:pt-10 flex items-center flex-col'
            )}>
            <div
              className={cx(
                blogTemplateStyles.blog__info,
                'justify-center flex flex-col md:px-12 md:py-10 p-5'
              )}>
              <h1 className="font-gordo mb-3 text-gray-800 tracking-wider text-2xl md:text-3xl md:leading-10">
                {md.frontmatter.title}
              </h1>
              <div
                className={'flex mb-4'}>
                <div
                  className={cx(
                    blogTemplateStyles.catsContainer,
                    'flex w-full justify-start'
                  )}>
                  {md.frontmatter.categories?.map(
                    (cat: string) => {
                      return (
                        <Category
                          tooltip={
                            md
                              .frontmatter
                              .categories
                              .length >
                            7
                          }
                          key={cat}
                          text={
                            md
                              .frontmatter
                              .categories
                              .length <
                              8 &&
                            screen ==
                              ScreenType.Desktop
                          }
                          category={cat}
                          size={20}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <h3 className="font-charisma text-gray-900 tracking-wide">
                {md.frontmatter.date}
              </h3>
            </div>

            {md.frontmatter
              .hero_image && (
              <figure
                className={cx(
                  blogTemplateStyles.blog__hero
                )}>
                {md.frontmatter
                  .hero_image
                  ?.childImageSharp ? (
                  // Local image
                  <Img
                    fluid={
                      md.frontmatter
                        .hero_image
                        .childImageSharp
                        .fluid
                    }
                    alt={
                      md.frontmatter
                        .title
                    }
                    className={cx(
                      blogTemplateStyles.image,
                      'md:rounded-xl md:shadow-md'
                    )}
                  />
                ) : (
                  // External URL
                  <img
                    src={
                      md.frontmatter
                        .heroImage
                    }
                    alt={
                      md.frontmatter
                        .title
                    }
                    className={cx(
                      blogTemplateStyles.image,
                      'md:rounded-xl md:shadow-md'
                    )}
                  />
                )}
              </figure>
            )}
            <div
              className={cx(
                blogTemplateStyles.blog__body,
                'p-6 md:px-10 md:pt-10 pb-14 w-full'
              )}
              dangerouslySetInnerHTML={{
                __html: renderContent(md.html),
              }}
            />
          </div>
          {/* <CommentCount config={disqusConfig} placeholder={'...'} />
          <Disqus config={disqusConfig} /> */}
          <PageFooter />
        </section>
      </article>
    </>
  );
}

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query ($slug: String!) {
    site {
        siteMetadata {
          siteUrl
        }
      }
    markdownRemark(
      fields: {slug: {eq: $slug}}
    ) {
      fields {
        slug
      }
      excerpt
      frontmatter {
        title
        author
        categories
        date(
          formatString: "MMMM Do, YYYY"
        )
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
      html
    }
  }
`;
